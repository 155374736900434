// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
import "../components/sidebar";

// import Swiper JS
import Swiper from 'swiper/bundle';


window.document.addEventListener("DOMContentLoaded", function () {
  
    // nav
    const navigation = document.querySelector('nav');
    const links = document.querySelectorAll('.nav-links__link');
  
    function handleScroll() {
      const scrolledToTop = window.scrollY === 0;
      if (scrolledToTop) {
        navigation.classList.remove('scrolled');
        links.forEach(el => {
          el.classList.remove('scrolled');
        });
      } else {
        navigation.classList.add('scrolled');
        links.forEach(el => {
          el.classList.add('scrolled');
        });
      }
    }
  
    window.addEventListener('scroll', handleScroll);

    const menuBtn = document.querySelector('.menu-btn');
    const menuDiv = document.querySelector('.nav-links');
    const menuLinks = document.querySelectorAll('.nav-links__link');

    menuBtn.addEventListener('click', () => {
      document.body.classList.toggle('no-scroll');
      menuBtn.classList.toggle('open');
      menuDiv.classList.toggle('open');
      menuLinks.forEach((el)=> {
        el.classList.toggle('open');
      });
    });


      //animations
  const options = {
    rootMargin: '0px',
    threshold: 0.1,
  };

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
    });
  }, options);
  
  const animatedDivs = document.querySelectorAll('.a-div');
  const opacityDivs = document.querySelectorAll('.o-div');

  animatedDivs.forEach((div) => {
    observer.observe(div);
  });

  opacityDivs.forEach((div) => {
    observer.observe(div);
  });


  // delaying animation
  const delayObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
    });
  }, options);

  // categories details
  const categoryDivs = document.querySelectorAll('.categories-details__category');
  let category_counter = 1;
  categoryDivs.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${category_counter * 0.1}s`;
    category_counter += 1;
  });

  // news details
  const newsDiv = document.querySelectorAll('.news__item');
  let news_counter = 1;
  newsDiv.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${news_counter * 0.1}s`;
    news_counter += 1;
  });

  // blog posts
  const blogDiv = document.querySelectorAll('.post__container');
  let blog_counter = 1;
  blogDiv.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${blog_counter * 0.1}s`;
    blog_counter += 1;
  });

  // for product list
  const productsDivs = document.querySelectorAll('.product__container');
  let products_counter = 1;
  productsDivs.forEach((div) => {
    delayObserver.observe(div);
    div.style.animationDelay = `${products_counter * 0.1}s`;
    products_counter += 1;
  });

  //testemonials slider
  const testSwiper = new Swiper('.testemonials', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 'auto',
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

    //product details slider
    const productDetailsSwiper = new Swiper('.m-product-details-slider', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 'auto',
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    //products slider
    const productSwiper = new Swiper('.products-slider', {
      // Optional parameters
      direction: 'horizontal',
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false,
      // },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        998: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });


  //modal opener
    const modalBtns = document.querySelectorAll('.testemonials__testemonial-btn');
    const modalsContainer = document.querySelector('.testemonial-modals');
    const modals = document.querySelectorAll('.testemonial-modals__modal');
    const body = document.body;

    // Open Modal
    if (modalBtns) {
      modalBtns.forEach((btn) => {
        btn.addEventListener('click', function () {
          const modalId = this.id.replace('triger-', ''); 
          const modal = document.querySelector(`#modal-${modalId}`);
          modalsContainer.classList.add('active');
          modal.classList.add('active');
          body.style.overflow = 'hidden'; 
        });
      });
    }

    // Close Modal
    if (modalsContainer) {
      modalsContainer.addEventListener('click', function (event) {
        modals.forEach((modal) => {
          modal.classList.remove('active');
        });
        if (event.target === this) {
          this.classList.remove('active');
          body.style.overflow = 'auto';
        }
      });
    }

    modals.forEach((modal) => {
      const closeButton = modal.querySelector('.close-button');
      if (closeButton) {
        closeButton.addEventListener('click', function () {
          modal.classList.remove('active');
          modalsContainer.classList.remove('active');
          body.style.overflow = 'auto';
        });
      }
    });

    //nav cart functionality
    let cartLink = document.querySelector('#cart-link');
    let cartItemsCount = document.querySelector('#nav-cart-items');
    let addToCartBtns = document.querySelectorAll('.add-to-cart-form');
    let addingToCart = false;
    addToCartBtns.forEach(element => {
      element.addEventListener('submit', function (event) {
        event.preventDefault();
        if (!addingToCart) {
          addingToCart = true;  
          var feedbackElement = element.querySelector('.feedback');
          feedbackElement.classList.add('show');
          let currentItemCount = parseInt(cartItemsCount.textContent, 10);
          currentItemCount += 1;
          cartItemsCount.textContent = currentItemCount;
          if (currentItemCount > 0) {
            cartItemsCount.removeAttribute('hidden');
            cartLink.classList.remove('disabled');
          }
    
          setTimeout(function() {
              feedbackElement.classList.remove('show');
              addingToCart = false;
          }, 1500);
        }
      });
    });

    //favorite products functionality
    let favForms = document.querySelectorAll('.product-favorits__form');

    favForms.forEach(function (form) {
        let button = form.querySelector('button');

        button.addEventListener('click', function () {
            form.classList.toggle('active');
        });
    });

    //quantity add/remove functionality
    const qtyForm = document.querySelector('.product-details-form');
    const decreaseQty = document.getElementById('decreaseNumber');
    const increaseQty = document.getElementById('increaseNumber');

    if(qtyForm) {
      decreaseQty.addEventListener('click', function(event) {
        event.preventDefault();
        let quantityInput = document.getElementById('id_quantity');
        let currentQtyValue = parseInt(quantityInput.value, 10);
        if (currentQtyValue > 1) {
          quantityInput.value = currentQtyValue - 1;
      }
      });
  
      increaseQty.addEventListener('click', function(event) {
        event.preventDefault();
        let quantityInput = document.getElementById('id_quantity');
        let currentQtyValue = parseInt(quantityInput.value, 10);
        if (currentQtyValue < 20) {
          quantityInput.value = currentQtyValue + 1;
      }
      });
    }





  

});

